input,
textarea {
  &.heatApp::placeholder {
    color: var(--colors-text-body-0);
    opacity: 0.4;
  }

  &.olandschokladApp::placeholder {
    color: var(--colors-text-body-0);
    opacity: 0.5;
  }
}
