@import '../../theme/styles/mixins';

.gridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;

  button {
    height: 70px;
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    border: none;
    cursor: pointer;

    &::after {
      border-radius: 8px;
    }

    &:hover::after {
      background-color: var(--colors-highlights-1) !important;
    }

    svg {
      height: 24px;
      width: 24px;
    }

    &.heatApp {
      &::after {
        background-color: var(--colors-surfaces-primary-4) !important;
      }

      &:hover {
        svg {
          color: var(--colors-text-heading-1);
        }
      }

      &:hover::after {
        background-color: var(--colors-highlights-0) !important;
      }
    }
  }

  @include mobile {
    grid-template-columns: repeat(2, 1fr);
  }
}

.selected {
  &::after {
    border: 2px solid var(--colors-highlights-1);
  }

  &.heatApp::after,
  &.olandschokladApp::after {
    border: 2px solid var(--colors-button-primary-background-default);
  }
}
