.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  > div {
    margin: 10px 0px 10px 0px;
  }

  p {
    margin-left: 18px;
  }

  border-bottom: 1px solid var(--colors-surfaces-primary-1);

  &.disabled {
    opacity: 0.5;
  }
}

.selectable {
  svg {
    fill: var(
      --colors-components-selectable-content,
      var(--colors-button-secondary-content)
    );
  }
}

.contentContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
