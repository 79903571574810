@import '../../../theme/styles/mixins';

.container {
  display: flex;
  flex-direction: row;
  padding: 12px 0;

  &.highlightingContainer {
    position: relative;
    padding-left: 8px;
    padding-right: 8px;
  }
}

.selectedItemContainer {
  display: flex;
  flex-direction: row;
  margin: 0 -20px;
  padding: 12px 20px;
  background-color: var(
    --colors-components-cartItem-selected,
    var(--colors-surfaces-primary-4)
  );
}

.itemQuantity {
  flex: 1;
  margin-right: 16px;
}

.quantityText {
  margin-right: 0px;
  max-width: 35px;
}

.quantityButton {
  background-color: var(--colors-surfaces-primary-1);
  color: var(--colors-text-body-0);
  font-size: 14px;
  padding: 3px 8px 3px 14px;

  svg {
    color: var(--colors-text-body-1);
    height: 32px;
    width: 32px;
    padding-left: 5px;
  }
}

.quantityButton:focus {
  background-color: var(--colors-surfaces-primary-1);
}

.itemDetailsContainer {
  flex: 6;
}

.noModifications {
  display: flex;
  align-items: center;
}

.itemCost {
  flex: 2;
  text-align: right;
}

.discountPrice {
  text-decoration: line-through;
}

.separator {
  background-color: var(--colors-surfaces-primary-1);
  border: none;
  height: 0.5px;
}

.productNameContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.highlight {
  --highlight-gap: 6px;

  position: absolute;
  top: calc(var(--highlight-gap) / 2);
  right: 0;
  background-color: var(--colors-components-cartItem-highlight);
  border-radius: 8px;
  height: calc(100% - var(--highlight-gap));
  min-height: 40px;
  width: 100%;
}
