@import '../../../../theme/styles/mixins';

.button {
  padding: 10px 15px;
  border-radius: 50px;
  display: flex;
  justify-content: flex-start;
  border: none;
  align-items: center;
  cursor: pointer;
  height: 36px;
  height: 100%;
  flex-grow: 1;

  &::after,
  &:focus::after,
  &:hover::after {
    background-color: var(
      --colors-components-navBar-button-background-0
    ) !important;
  }

  &:active::after {
    filter: none !important;
  }

  @include tablet {
    &::after,
    &:focus::after,
    &:hover::after {
      background-color: var(
        --colors-button-secondary-background-default
      ) !important;
    }
  }

  .serviceTimeData {
    display: flex;
    align-items: center;
    color: var(--colors-components-navBar-button-content-default-0);

    @include tablet {
      color: var(--colors-button-secondary-content);
    }

    svg {
      height: 16px;
      width: 16px;
      margin-right: 8px;
      fill: var(--colors-components-navBar-button-content-accent-0);

      @include tablet {
        fill: var(--colors-button-secondary-content);
      }
    }
  }

  &.selected {
    &::after,
    &:focus::after,
    &:hover::after {
      background-color: var(
        --colors-components-navBar-button-background-1
      ) !important;
    }

    &:active::after {
      filter: none !important;
    }

    @include tablet {
      color: var(--colors-button-secondary-content);

      &::after,
      &:focus::after,
      &:hover::after {
        background-color: var(--colors-highlights-0) !important;
      }

      &.olandschokladApp {
        &::after,
        &:focus::after,
        &:hover::after {
          background-color: var(--colors-highlights-1) !important;
        }
      }
    }
  }

  &.inNavBar {
    .serviceTimeData {
      color: var(--colors-components-navBar-button-content-default-0);

      svg {
        fill: var(--colors-components-navBar-button-content-accent-0);
      }
    }

    &.selected {
      .serviceTimeData {
        color: var(--colors-components-navBar-button-content-default-1);

        svg {
          fill: var(--colors-components-navBar-button-content-accent-1);
        }
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
  }
}
