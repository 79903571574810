@import '../../../theme/styles/mixins';

.footer {
  background-color: var(--colors-surfaces-body);
  padding: 30px 0;
  border-top: 1px solid var(--colors-surfaces-primary-1);

  &.cartDrawerOpen {
    @include xxDesktop {
      margin-right: 420px;
    }

    @include xDesktop {
      margin-right: 0px;
    }
  }

  .primaryRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;

    @include mobile {
      flex-direction: column;
      align-items: center;
    }

    .primaryLeftContainer {
      display: flex;
      flex-direction: row;
      align-items: center;

      button {
        height: 36px;

        @include mobile {
          display: none;
        }
      }

      .footerBrand {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 30px;

        @include mobile {
          margin-right: 0;
        }

        img {
          width: 176px;
          object-fit: contain;

          @include mobile {
            width: 145px;
            margin-bottom: 10px;
          }
        }
      }
    }

    .orderingTagLine {
      font-size: 24px;

      margin-left: 30px;
      text-align: right;

      @include mobile {
        text-align: center;
        margin-left: 0px;
      }
    }
  }

  .secondaryRow {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;

    @include mobile {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  .linksRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include mobile {
      align-items: center;
      text-align: center;
      flex-direction: column;
    }

    a {
      p {
        font-weight: var(--font-heading-weight);
        text-decoration: underline;
      }
    }

    .leftLinksContainer {
      > button {
        display: none;
      }

      @include mobile {
        align-items: center;
        text-align: center;
        flex-direction: column;

        > button {
          display: block;
          margin: 20px 0px;
        }
      }

      .linksContainer {
        display: flex;
        flex-direction: row;
        margin-right: 15px;

        @include mobile {
          margin-bottom: 15px;
        }

        .linksSeparator {
          font-weight: var(--font-heading-weight);
          margin: 0 5px;
        }
      }
    }

    .rightLinksContainer {
      .versionText {
        margin-right: 10px;

        @include mobile {
          margin-top: 10px;
        }
      }
    }

    .leftLinksContainer,
    .rightLinksContainer {
      display: flex;
    }
  }

  &.whiteLabeled {
    .primaryRow {
      .primaryLeftContainer {
        .footerBrand {
          img {
            width: auto;
            height: 36px;
          }

          &.cornerApp {
            img {
              height: 40px;
            }
          }

          &.lunchboxApp {
            img {
              height: 20px;

              @include mobile {
                height: 18px;
              }
            }
          }

          &.heatApp {
            img {
              height: 40px;
              margin-bottom: 8px;

              @include mobile {
                margin-bottom: 0;
              }

              @include smallMobile {
                height: 36px;
              }
            }
          }

          &.olandschokladApp {
            img {
              height: 50px;

              @include mobile {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  .displayMobile {
    display: none;

    @include smallMobile {
      display: block;
    }
  }

  .displayDesktop {
    display: block;

    @include smallMobile {
      display: none;
    }
  }
}
